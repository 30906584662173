<template>
  <section class="pt-4 px-0 px-xl-4 h-100">
    <b-container fluid>
      <b-row class="pb-3">
        <b-col cols="3">
          <b-select v-model="brandId" :options="brands" />
        </b-col>
        <b-col cols="2">
          <b-input
            id="filter-input"
            v-model="filter"
            type="search"
            placeholder="Cerca"
          />
        </b-col>
        <b-col cols="6" class="text-right">
          -
        </b-col>
        <b-col cols="1" class="text-right">
          -
        </b-col>
      </b-row>
      <b-row>
        <b-table
          striped
          hover
          :fields="fields"
          :items="items"
          :busy="isBusy"
          v-model="visibleRows"
          :filter="filter"
          class="mt-3"
          outlined
          sticky-header="75vh"
        >
          <template #table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle m-2" />
              <strong>Loading...</strong>
            </div>
          </template>
          <template #cell(image)="data">
            <img
              :src="
                'https://tec.imgix.net/images/2000x2000/' +
                  data.item.itemId +
                  '-1.jpg?auto=format,compress&w=200&h=154&fit=fill'
              "
              alt="image"
              style="object-fit: contain; height: 40px; width: 80px;"
            />
          </template>
        </b-table>
      </b-row>
    </b-container>
  </section>
</template>

<script>
import * as XLSX from "xlsx";

export default {
  name: "SupplyManager",
  data() {
    return {
      fields: [
        { key: "image", label: "Immagine" },
        { key: "brand", label: "Produttore", sortable: true },
        { key: "code", label: "Codice", sortable: true },
        { key: "name", label: "Descrizione" },
        { key: "goodOnHand", label: "Inventario", sortable: true },
        { key: "saa", label: "Acquistati", sortable: true },
        { key: "costPrice", label: "Netto" },
        { key: "salesPerPeriod", label: "Vendita" },
        { key: "purchaseQuantity", label: "Ricarico", sortable: true },
        { key: "safetyStock", label: "Sconto", sortable: true },
        { key: "restockingLevel", label: "Listino" }
      ],
      items: [],
      brandId: null,
      brands: [],
      isBusy: false,
      visibleRows: [],
      filter: null,
      downloadPrice: false
    };
  },
  mounted() {
    this.getBrand();
  },
  watch: {
    brandId() {
      this.isBusy = true;
      this.items = [];
      this.getTurnover();
    },
    items(value) {
      if (value.length > 0) this.isBusy = false;
    }
  },
  computed: {
    total() {
      return this.visibleRows.reduce((accum, item) => {
        return accum + item.costPrice * item.suggestQuantity;
      }, 0);
    },
    totalStock() {
      return this.visibleRows.reduce((accum, item) => {
        return accum + item.surplusValueStock;
      }, 0);
    },
    brandName() {
      if (this.brandId)
        return this.brands.find(option => option.value === this.brandId)
          .brandName;
      return "";
    }
  },
  methods: {
    getBrand: function() {
      this.$http
        .get(`${process.env.VUE_APP_API_ENDPOINT}brand-all`)
        .then(res => {
          this.brands = this.brandSelectAdapter(res.data);
        })
        .catch(err => {
          this.error = err;
        });
    },
    brandSelectAdapter: function(data) {
      let result = [{ value: null, text: "Selezionare un brand" }];
      data.map(function(element) {
        result.push({
          value: element.id,
          text: element.name + " (" + element.itemsNumber + ")",
          brandName: element.name
        });
      });
      this.isBusy = false;
      return result;
    },
    getTurnover: function() {
      this.$http
        .get(
          `${process.env.VUE_APP_API_ENDPOINT}turnover/restocking?brandId=${this.brandId}`
        )
        .then(res => {
          this.items = res.data;
        })
        .catch(err => {
          this.error = err;
        });
    },
    currencyFormatter(number) {
      let currencyFormatter = new Intl.NumberFormat("it-IT", {
        style: "currency",
        currency: "EUR"
      });
      return currencyFormatter.format(number);
    },
    showDate(date) {
      if (date) return date.split(" ")[0];
      return "";
    },
    downloadExcel() {
      const downloadPrice = this.downloadPrice;
      const order = this.visibleRows.reduce((ac, order) => {
        if (order.suggestQuantity > 0) {
          let item = {
            code: order.code,
            description: order.name
          };
          if (downloadPrice) item.price = order.costPrice;
          item.quantity = order.suggestQuantity;
          ac.push(item);
        }
        return ac;
      }, []);
      const orderWS = XLSX.utils.json_to_sheet(order);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, orderWS);
      XLSX.writeFile(
        wb,
        this.brandName + "_order_" + this.getCurrentDate() + ".xlsx"
      );
    },
    getCurrentDate() {
      const date = new Date();
      return (
        date.getUTCFullYear() +
        "-" +
        ("0" + (date.getUTCMonth() + 1)).slice(-2) +
        "-" +
        ("0" + date.getUTCDate()).slice(-2)
      );
    }
  }
};
</script>
